<template>
  <textarea
    :id="id"
    ref="$el"
    v-model="model"
    :class="[{ error }, props.theme, props.size]"
    :disabled="props.disabled"
    :placeholder="props.placeholder"
    :required="props.required"
    :rows="props.rows"
    tabindex="0"
    @blur="validate"
  />
</template>

<script setup>
// Emitter
const emit = defineEmits([
  'textarea:change',
  'textarea:validate',
])

// Props
const props = defineProps({
  content: {
    default() {
      return ''
    },
    required: false,
    type: String,
  },
  disabled: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  id: {
    default() {
      return undefined
    },
    required: false,
    type: String,
  },
  placeholder: {
    default() {
      return ''
    },
    required: false,
    type: String,
  },
  required: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  rows: {
    default() {
      return 5
    },
    required: false,
    type: Number,
  },
  size: {
    default() {
      return 'm'
    },
    required: false,
    type: String,
    validator(value) {
      return ['m', 'l'].indexOf(value) !== -1
    },
  },
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
})

// Refs
const $el = ref(null)
const error = ref(false)
const model = ref('')

// Watchers
watch(
  () => model.value,
  (value) => {
    emit('textarea:change', value)
  },
)

// Methods
function getValue() {
  return model.value
}

function validate() {
  error.value = !$el.value.checkValidity()
  emit('textarea:validate', error.value)
}

// Lifecycle Hooks
onBeforeMount(() => {
  model.value = props.content
})

// Expose
defineExpose({
  $el,
  getValue,
})
</script>

<style lang="postcss" scoped>
textarea {
  @apply w-full rounded border font-sans text-sm p-3 resize-none transition duration-300 ease-out cursor-text;

  &.dark {
    @apply bg-[#182427] border-[#5D6669] text-white;

    &.error {
      @apply border-red-600;
    }

    &:focus {
      @apply border-[#CED0D0];
    }

    &:hover {
      @apply border-[#CED0D0];
    }

    &::placeholder {
      @apply text-white/50;
    }
  }

  &.light {
    @apply bg-white border-grey-blue-300 text-grey-blue-900;

    &.error {
      @apply border-red-600;
    }

    &:disabled {
      @apply bg-grey-blue-100 border-grey-blue-200;
    }

    &:focus {
      @apply border-livestorm-blue-700;
    }

    &:hover {
      @apply border-grey-blue-600;
    }

    &::placeholder {
      @apply text-grey-blue-600;
    }
  }

  &.l {
    @apply px-4 text-origin;
  }

  &:focus {
    @apply outline-none;
  }

  &:hover {
    @apply outline-none;
  }
}
</style>
